import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Logo from "./logo";
import NavBar from "./navbar";
import styles from "./header.module.css";


const Header = ({ siteTitle, scrolled, fixedBgColor, backgroundOpacity, backgroundColor, navColor, forceShowLogo = true }) => (
  <header
    className={`${styles.wrapper} ${scrolled && !fixedBgColor && styles.scrolled}`}
    style={{ backgroundColor }}
  >
    <div className={styles.innerWrapper}>
      <div className={`${styles.logoWrapper} ${(true || forceShowLogo) && styles.logoShow}`}>
        <Link to={'/'}>
          <Logo fill={navColor}/>
        </Link>
      </div>
      <NavBar color={navColor} centered={!forceShowLogo && false} backgroundColor={scrolled && !fixedBgColor ? '#fff' : backgroundColor} />
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
