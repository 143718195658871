import { Link } from "gatsby"
import Logo from "./logo";
import PropTypes from "prop-types"
import React from "react"
import moment from 'moment';
import styles from './footer.module.css';
import waves from '../images/wavy2.png';
const Footer = ({ siteTitle, centered = false, backgroundColor='white', color = 'white' }) => (
  <div style={{ backgroundColor }}>
    <div className={styles.waves} style={{ height: 120, backgroundImage: `url('${waves}')`, backgroundSize: 'contain', width: '100%' }}></div>
    <div className={styles.wrap}>
      <Logo />
      <ul className={styles.links} style={{ color }}>
        <li><Link to="/#products">Products & Projects</Link></li>
        <li><Link to="/collaborate">Work Together</Link></li>
      </ul>
      <div className={styles.copyright}>
        © {moment().format('YYYY')} Long Weekend
      </div>
    </div>
  </div>
)

export default Footer
