/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import Footer from "./footer"
import "./layout.css"

const Layout = ({ children, excludeHeader, fixedBgColor = false, bodyBgColor, forceShowLogo = true, headerBackground, navColor }) => {

  const [showFixedHeader, setShowFixedHeader] = useState(false);
  const [headerBackgroundOpacity, setHeaderBackgroundOpactiy] = useState(1);
  const [scrolled, setScrolled] = useState(false);

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const isBrowser = typeof window !== `undefined`

  const handleScroll = (e) => {
    if (window.scrollY > 80) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  }

  useEffect(() => {
    if (!isBrowser) {
      return;
    }
    window.addEventListener('scroll', handleScroll)

    return () => window.removeEventListener('scroll', handleScroll)
  }, []);

  return (
    <>
      <div
        style={{
          margin: `0 auto`,
        }}
      >
        <Header
          backgroundColor={headerBackground}
          scrolled={scrolled}
          fixedBgColor={fixedBgColor}
          navColor={navColor}
          backgroundOpacity={headerBackgroundOpacity}
          forceShowLogo={forceShowLogo || scrolled}
        />
        <main className={excludeHeader ? "" : "mainWrap"}>{children}</main>
        <Footer backgroundColor={bodyBgColor}/>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
