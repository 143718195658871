import React, { useState } from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import ScrollLock, { TouchScrollable } from 'react-scrolllock';
import styles from './navbar.module.css';

const NavBar = ({ siteTitle, centered = false, color = 'white', backgroundColor='#fff' }) => {
  const [menuOpen, setMenuOpen] = useState(false)
  return (
    <>
      <nav
        className={`${styles.wrapper} ${centered ? null : styles.rightAligned}`}
      >
        <div className={styles.desktopNav}>
          <ul className={styles.links} style={{ color }}>
            <li><a href="/#products">Products & Projects</a></li>
            <li><Link to="/collaborate">Work Together</Link></li>
          </ul>
        </div>
        <div className={styles.mobileNav}>
          <button className={styles.icon} style={{ fill: color }} aria-label="menu" onClick={() => setMenuOpen(!menuOpen)}>
            {
              menuOpen ?
              <svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M14.2695 16.3908L4 6.12132L6.12132 4L16.3908 14.2695L26.6603 4L28.7817 6.12132L18.5122 16.3908L28.7817 26.6603L26.6603 28.7817L16.3908 18.5122L6.12132 28.7817L4 26.6603L14.2695 16.3908Z" />
              </svg> :
              <svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M32 18L0 18L0 15L32 15V18Z"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M32 11H0V8H32V11Z"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M32 25L-2.38498e-08 25L2.38419e-07 22L32 22L32 25Z"/>
              </svg>
            }
          </button>
        </div>
      </nav>
      {
        menuOpen ?
        <div className={styles.openMenu} style={{ backgroundColor }} onClick={() => {
          setMenuOpen(false)
        }}>
          <ScrollLock />
          <ul className={styles.links} style={{ color }}>
            <li><a href="/#products">Products & Projects</a></li>
            <li><Link to="/collaborate">Work Together</Link></li>
          </ul>
        </div> :
        null
      }
      </>
  )
}

export default NavBar
